/* eslint-disable no-irregular-whitespace */
/* eslint import/no-extraneous-dependencies: "off" */
/* eslint no-alert: "off" */
/* eslint max-len: "off" */

// import node_modules
import React from 'react';

// import React components
import Layout from '../components/Layout';

// declare custom component
const Datenschutz = () => (
  <Layout
    description="Privacy policy"
    image="/img/silke-blume-hero-background.png"
    pathname="/privacy-policy/"
    title="Privacy Policy | Silke Blume"
  >
    <section id="datenschutz" className="is-text">
      <div className="container">
        <div className="columns is-mobile is-centered">
          <div className="column is-three-quarters-tablet is-two-thirds-desktop is-three-fifths-widescreen is-half-fullhd">
            <h1 className="title is-size-2 is-padding-top-1">Datenschutzerklärung</h1>
            <p>Wir führen unsere Webseiten nach den im Folgenden geregelten Grundsätzen:</p>
            <p>
              Wir verpflichten uns, die gesetzlichen Bestimmungen zum Datenschutz einzuhalten und
              bemühen uns, stets die Grundsätze der Datenvermeidung und der Datenminimierung zu
              berücksichtigen.
            </p>
            <p>
              Der Verantwortliche im Sinne der Datenschutz-Grundverordnung und anderer nationaler
              Datenschutzgesetze der Mitgliedsstaaten der Europäischen Union sowie sonstiger
              datenschutzrechtlicher Bestimmungen ist:
              <br />
              <br />
              ICA In Crowd UG
              <br />
              vertr. d.d. Geschäftsführerin Silke Blume
              <br />
              Hochallee 118
              <br />
              20149 Hamburg
              <br />
              Deutschland
              <br />
              Tel.: 0162/ 5744443
              <br />
              E-Mail: silke.blume@incrowdassets.com
              <br />
              Webseite: www-ica-incrowd.com
              <br />
              <br />
            </p>

            <h2>Begriffserklärungen</h2>
            <p>
              Wir haben unsere Datenschutzerklärung nach den Grundsätzen der Klarheit und
              Transparenz gestaltet. Sollten dennoch Unklarheiten in Bezug auf die Verwendung von
              verschiedenen Begrifflichkeiten bestehen, können die entsprechenden Definitionen{' '}
              <a href="https://dsgvo-gesetz.de/art-4-dsgvo/">hier</a> eingesehen werden.
            </p>

            <h2>Rechtsgrundlage für die Verarbeitung von personenbezogenen Daten</h2>
            <p>
              Wir verarbeiten Ihre personenbezogenen Daten wie bspw. Ihren Namen und Vornamen, Ihre
              E-Mail-Adresse und IP-Adresse usw. nur, wenn hierfür eine gesetzliche Grundlage
              gegeben ist. Hier kommen nach der Datenschutzgrundverordnung insbesondere drei
              Regelungen in Betracht:
            </p>
            <ul>
              <li>
                Sie haben uns Ihre Einwilligung zu der Verarbeitung Ihrer personenbezogenen Daten
                für einen oder mehrere Zwecke erteilt, Art. 6 Abs. 1 S. 1 lit. a DSGVO. Sie werden
                in diesem Zusammenhang von uns über den Zweck bzw. die Zwecke der Verarbeitung
                ausführlich informiert und Ihre ausdrückliche Einwilligung wird bei uns
                dokumentiert.
              </li>
              <li>
                Die Verarbeitung Ihrer personenbezogenen Daten ist für die Erfüllung eines Vertrags
                oder zur Durchführung vorvertraglicher Maßnahmen mit Ihnen erforderlich, Art. 6
                Abs. 1 S. 1 lit. b DSGVO.
              </li>
              <li>
                Die Verarbeitung der personenbezogenen Daten ist zur Wahrung unserer berechtigten
                Interessen erforderlich, sofern nicht Ihre Interessen oder Grundrechte und
                Grundfreiheiten überwiegen, Art. 6 Abs. 1 S. 1 lit. f DSGVO.
              </li>
            </ul>
            <p>
              Wir weisen Sie aber an den jeweiligen Stellen immer noch einmal darauf hin, auf
              welcher Rechtsgrundlage die Verarbeitung Ihrer personenbezogenen Daten erfolgt.
            </p>

            <h2>Weitergabe der personenbezogenen Daten</h2>
            <p>
              Eine Übermittlung Ihrer persönlichen Daten an Dritte zu anderen als den im Folgenden
              aufgeführten Zwecken findet nicht statt. Wir geben Ihre persönlichen Daten nur an
              Dritte weiter, wenn:
            </p>
            <ul>
              <li>
                Sie Ihre ausdrückliche Einwilligung nach Art. 6 Abs. 1 S. 1 lit. a DSGVO dazu
                erteilt haben,
              </li>
              <li>
                die Weitergabe nach Art. 6 Abs. 1 S. 1 lit. f DSGVO zur Geltendmachung, Ausübung
                oder Verteidigung von Rechtsansprüchen erforderlich ist und kein Grund zur Annahme
                besteht, dass Sie ein überwiegendes schutzwürdiges Interesse an der Nichtweitergabe
                Ihrer Daten haben,
              </li>
              <li>
                für die Weitergabe nach Art. 6 Abs. 1 S. 1 lit. c DSGVO eine gesetzliche
                Verpflichtung besteht
              </li>
              <li>
                dies gesetzlich zulässig und nach Art. 6 Abs. 1 S. 1 lit. b DSGVO für die Abwicklung
                von Vertragsverhältnissen mit Ihnen erforderlich ist.
              </li>
            </ul>

            <h2>Speicherdauer und Löschung</h2>
            <p>
              Wir speichern alle personenbezogenen Daten, die Sie uns übermitteln, nur so lange, wie
              sie benötigt werden, um die Zwecke zu erfüllen, zu denen diese Daten übermittelt
              wurden, oder solange dies von Gesetzes wegen vorgeschrieben ist. Mit Zweckerfüllung
              und/oder Ablauf der gesetzlichen Speicherungsfristen werden die Daten von uns gelöscht
              oder gesperrt.
            </p>

            <h2>SSL-Verschlüsselung</h2>
            <p>
              Diese Webseite nutzt aus Gründen der Sicherheit und zum Schutz der Übertragung
              vertraulicher Inhalte, wie z.B. der Anfragen, die Sie an uns als Webseitenbetreiber
              senden, eine SSL-Verschlüsselung. Eine verschlüsselte Verbindung erkennen Sie daran,
              dass die Adresszeile des Browsers von „http://” auf „https://” wechselt und an dem
              Schloss-Symbol in Ihrer Browserzeile.
              <br />
              Wenn die SSL-Verschlüsselung aktiviert ist, können die Daten, die Sie an uns
              übermitteln, nicht von Dritten mitgelesen werden.
            </p>

            <h2>
              Erhebung und Speicherung von personenbezogenen Daten sowie deren Art und Zweck der
              Verwendung
            </h2>
            <p>
              <strong>a) Beim Besuch der Webseite </strong>
            </p>
            <p>
              Beim Aufrufen unserer Webseite werden durch den auf Ihrem Endgerät zum Einsatz
              kommenden Browser automatisch Informationen an den Server unserer Webseite gesendet.
              Diese Informationen werden temporär in einem sogenannten Logfile gespeichert. Folgende
              Informationen werden dabei ohne Ihr Zutun erfasst und bis zur automatisierten Löschung
              gespeichert:
            </p>
            <ul>
              <li>IP-Adresse des anfragenden Rechners</li>
              <li>Datum und Uhrzeit des Zugriffs</li>
              <li>Name und URL der abgerufenen Datei</li>
              <li>Webseite, von der aus der Zugriff erfolgt (Referrer-URL)</li>
              <li>
                verwendeter Browser und ggf. das Betriebssystem Ihres Rechners sowie der Name Ihres
                Access-Providers
              </li>
            </ul>

            <p>Die genannten Daten werden durch uns zu folgenden Zwecken verarbeitet:</p>

            <ul>
              <li>Gewährleistung eines reibungslosen Verbindungsaufbaus der Webseite</li>
              <li>Gewährleistung einer komfortablen Nutzung unserer Webseite</li>
              <li>Auswertung der Systemsicherheit und -stabilität</li>
              <li>Fehleranalyse</li>
              <li>zu weiteren administrativen Zwecken</li>
            </ul>

            <p>
              Daten, die einen Rückschluss auf Ihre Person zulassen, wie bspw. die IP-Adresse,
              werden spätestens nach 7 Tagen gelöscht. Sollten wir die Daten über diesen Zeitraum
              hinaus speichern, werden diese Daten pseudonymisiert, so dass eine Zuordnung zu Ihnen
              nicht mehr möglich ist. Die Rechtsgrundlage für die Datenverarbeitung ist Art.
              6 Abs. 1 S. 1 lit. f DSGVO. Unser berechtigtes Interesse folgt aus oben aufgelisteten
              Zwecken zur Datenerhebung. In keinem Fall verwenden wir die erhobenen Daten zu dem
              Zweck, Rückschlüsse auf Ihre Person zu ziehen.
            </p>

            <p>
              <strong>b) Blog</strong>
            </p>

            <p>
              Wenn Sie einen Blogbeitrag kommentieren möchten, dann werden Ihr Name, Ihre
              E-Mail-Adresse sowie Ihre IP-Adresse erhoben und gespeichert. Die Rechtsgrundlage
              ergibt sich hier aus Art. 6 Abs. S. 1 lit. f DSGVO zur Wahrnehmung berechtigter
              Interessen, da die Speicherung dieser Daten zu unserer Sicherheit notwendig ist, da
              wir für widerrechtliche Inhalte auf unserer Webseite belangt werden können. Anderen
              Besuchern unserer Webseite werden bei Ihrem Kommentar das Datum und die Uhrzeit des
              Kommentars sowie Ihr Name als Verfasser angezeigt.
            </p>

            <p>
              <strong>c) Kontaktformular / E-Mail-Kontakt</strong>
            </p>
            <p>
              Wir stellen Ihnen auf unserer Webseite ein Formular zur Verfügung, so dass Sie die
              Möglichkeit haben, jederzeit Kontakt mit uns aufzunehmen. Für die Verwendung des
              Kontaktformulars ist die Angabe eines Namens für eine persönliche Anrede und einer
              gültigen E-Mail-Adresse zur Kontaktaufnahme notwendig, damit wir wissen, von wem die
              Anfrage stammt und diese auch bearbeiten können. Wenn Sie uns per Kontaktformular
              Anfragen zukommen lassen, werden Ihre Angaben aus dem Anfrageformular inklusive der
              von Ihnen dort angegebenen Kontaktdaten sowie Ihre IP-Adresse gem. Art. 6 Abs. 1
              S. 1 lit. b und f DSGVO zur Durchführung vorvertraglicher Maßnahmen, die auf Ihre
              Anfrage hin erfolgen bzw. zur Wahrnehmung unseres berechtigten Interesses, nämlich zur
              Ausübung unserer geschäftlichen Tätigkeit verarbeitet. Gerne können Sie uns auch
              stattdessen eine E-Mail unter Verwendung der auf unserer Webseite angegebenen
              E-Mail-Adresse zusenden. Wir speichern und verarbeiten in diesem Fall Ihre
              E-Mail-Adresse sowie die von Ihnen im Rahmen der E-Mail gemachten Angaben gemäß
              Art. 6 Abs. 1 S. 1 lit. b und f DSGVO zur Bearbeitung Ihrer Nachricht. Die Anfragen
              sowie die damit einhergehenden Daten werden spätestens 3 Monate nach Erhalt gelöscht,
              sofern diese nicht für eine weitere vertragliche Beziehung benötigt werden.
            </p>

            <p>
              <strong>d) Google Fonts</strong>
            </p>

            <p>
              Auf unseren Internetseiten verwenden wir Google Fonts. Hierdurch wir die Darstellung
              von Schriften ermöglicht. Google Fonts ist ein Dienst der Google Inc. (1600
              Amphitheatre Parkway, Mountain View, California, 94043). Die Einbindung dieser Web
              Fonts erfolgt durch einen Serveraufruf, in der Regel ein Server von Google in den USA.
              Hierdurch wird an den Server möglicherweise folgendes übermittelt und von Google
              gespeichert:
            </p>

            <ul>
              <li>Name und Version des verwendeten Browsers</li>
              <li>Webseite, von der die Anfrage ausgelöst wurde (Referrer-URL)</li>
              <li>Betriebssystem Ihres Rechners</li>
              <li>Bildschirmauflösung Ihres Rechners</li>
              <li>IP-Adresse anfragenden Rechners</li>
              <li>Spracheinstellungen des Browsers bzw. des Betriebssystems, das der User nutzt</li>
            </ul>

            <p>
              Nähere Informationen finden Sie in den Datenschutzhinweisen von Google, die Sie hier
              abrufen können:
              <br />
              www.google.com/fonts#AboutPlace:about
              <br />
              www.google.com/policies/privacy/
              <br />
              Der Einsatz von Google Fonts dient dazu, Ihnen das Lesen unserer Webseite zu
              vereinfach und grafisch angenehmer zu gestalten und erfolgt somit auf Grundlage
              unserer berechtigten Interessen gem. Art. 6 Abs. 1 S. 1 lit. f DSGVO.
            </p>

            <p>
              <strong>e) Verwendung von Google Maps</strong>
            </p>

            <p>
              Unsere Webseite verwendet die Google Maps API. Durch die Nutzung von Google Maps
              können Informationen über Ihre Benutzung dieser Webseite (einschließlich Ihrer
              IP-Adresse) an einen Server von Google (Google Inc., 1600 Amphitheatre Parkway,
              Mountain View, California, 94043) in den USA übertragen und dort gespeichert werden.
              <br />
              Google wird die durch Maps gewonnenen Informationen gegebenenfalls an Dritte
              übertragen, sofern dies gesetzlich vorgeschrieben ist oder soweit Dritte diese Daten
              im Auftrag von Google verarbeiten. Ihre IP-Adresse wird aber auf keinen Fall mit
              anderen Daten von Google in Verbindung gebracht. Dennoch müssen wir Sie darauf
              hinweisen, dass es technisch durchaus möglich wäre, dass Google einzelne Nutzer auf
              Grund der erhaltenen Daten identifizieren könnte.
              <br />
              Wir haben keinen Einfluss darauf, ob Ihre personenbezogenen Daten und
              Persönlichkeitsprofile von Google für andere Zwecke verarbeitet werden. Wenn Sie dies
              auf jeden Fall vermeiden wollen, dann können Sie den Service von Google Maps
              deaktivieren und somit den Datentransfer zu Google unterbinden. Hierzu müssen Sie
              lediglich JavaScript in Ihrem Browser deaktivieren. In diesem Fall werden keine Daten
              übertragen, Sie können aber die Kartenanzeige auf unserer Webseite auch nicht mehr
              nutzen. Die Google-Datenschutzerklärung finden Sie{' '}
              <a href="https://www.google.com/policies/privacy/?hl=de">hier</a>.<br />
              Der Einsatz von Google Maps stellt einen Service für Sie dar, damit Sie unseren
              Standort genau erkennen und ggfs. Ihren Besuch bei uns besser planen können. Die
              Verwendung von Google Maps erfolgt somit auf Grundlage unserer berechtigten Interessen
              gem. Art. 6 Abs. 1 S. 1 lit. f DSGVO.
            </p>

            <h2>Cookies</h2>
            <p>
              Auf unserer Webseite setzen wir Cookies ein. Cookies sind kleine Datenpakete, die Ihr
              Browser automatisch erstellt und die auf Ihrem Endgerät gespeichert werden, wenn Sie
              unsere Webseite besuchen. Diese Cookies dienen dazu, Informationen im Zusammenhang mit
              dem jeweils eingesetzten Endgerät abzulegen. Eine persönliche Identifikation Ihrer
              Person ist aber durch die Cookies nicht möglich. Die durch Cookies verarbeiteten Daten
              sind für die genannten Zwecke zur Wahrung unserer berechtigten Interessen sowie der
              Dritter nach Art. 6 Abs. 1 S. 1 lit. f DSGVO erforderlich. Die meisten Browser
              akzeptieren Cookies auf Grund der Browser-Voreinstellung automatisch. Sie können Ihren
              Browser jedoch so konfigurieren, dass entweder gar keine Cookies auf Ihrem Endgerät
              gespeichert werden oder aber zumindest ein Hinweis angezeigt wird, bevor ein neuer
              Cookie gespeichert wird. Wenn Sie die Cookie-Funktion in Ihrem Browser vollständig
              deaktivieren, kann es sein, dass Sie nicht alle Funktionen unserer Webseite nutzen
              können. Nachfolgend erläutern wir Ihnen die verschiedenen Arten von Cookies, die wir
              einsetzen.
            </p>
            <p>
              <strong>Session-Cookies</strong>
            </p>
            <p>
              Um Ihnen die Nutzung unseres Angebots angenehmer zu gestalten, setzen wir sogenannte
              Session-Cookies ein, um zu erkennen, dass Sie einzelne Seiten unserer Webseite bereits
              besucht haben. Diese Session Cookies werden nach Verlassen unserer Seite automatisch
              gelöscht.
            </p>
            <p>
              <strong>Temporäre Cookies</strong>
            </p>
            <p>
              Wir setzen ebenfalls Cookies ein, die es uns erlauben, Sie wiederzuerkennen, wenn Sie
              unsere Webseite erneut besuchen und unsere Dienste in Anspruch nehmen. So müssen Sie
              Ihre Eingaben und Einstellungen, die Sie das letzte Mal getätigt haben, nicht erneut
              vornehmen. Diese temporären Cookies werden für einen bestimmten festgelegten Zeitraum
              auf Ihrem Endgerät gespeichert.
            </p>
            <p>
              <strong>Cookies zu Optimierungszwecken</strong>
            </p>
            <p>
              Schließlich setzen wir auch Cookies zu Optimierungszwecken ein. Diese erfassen die
              Nutzung unserer Webseite statistisch und werden zum Zwecke der Optimierung des
              Angebots für Sie ausgewertet. Die Cookies ermöglichen die Wiedererkennung Ihres
              Internetbrowsers, wenn Sie unsere Webseite erneut besuchen. Diese Cookies werden nach
              einer jeweils definierten Zeit automatisch gelöscht.
            </p>

            <h2>Analyse- und Trackingtools</h2>
            <p>
              Wir setzen auf unserer Webseite die nachfolgend aufgelisteten Analyse- und
              Trackingtools ein. Diese dienen dazu, die fortlaufende Optimierung unserer Webseite
              sicherzustellen und diese bedarfsgerecht zu gestalten. Diese Interessen sind als
              berechtigt im Sinne des Art. 6 Abs. 1 S. 1 lit. f DSGVO anzusehen. Die jeweiligen
              Datenverarbeitungszwecke und Datenkategorien sind aus den entsprechenden Tools zu
              entnehmen.
            </p>
            <p>
              <strong>Google Analytics</strong>
            </p>
            <p>
              Wir benutzen auf unserer Webseite Google Analytics, einen Webanalysedienst der Google
              Inc. (https://www.google.de/ intl/de/about/) (1600 Amphitheatre Parkway, Mountain
              View, CA 94043, USA; im Folgenden „Google“). Google Analytics verwendet in diesem
              Zusammenhang Cookies (siehe Ziffer 8). Die durch das Cookie erzeugten Informationen
              über Ihre Benutzung dieser Webseite wie
            </p>
            <ul>
              <li>Name und Version des verwendeten Browsers</li>
              <li>Betriebssystem Ihres Rechners</li>
              <li>Webseite, von der aus der Zugriff erfolgt (Referrer-URL)</li>
              <li>IP-Adresse des anfragenden Rechners</li>
              <li>Uhrzeit der Serveranfrage</li>
            </ul>

            <p>
              werden in der Regel an einen Server von Google in den USA übertragen und dort
              gespeichert. Da wir die IP-Anonymisierung auf unserer Webseite aktiviert haben, wird
              Ihre IP-Adresse von Google jedoch innerhalb von Mitgliedstaaten der Europäischen Union
              oder in anderen Vertragsstaaten des Abkommens über den Europäischen Wirtschaftsraum
              zuvor gekürzt. Nur in Ausnahmefällen wird die volle IP-Adresse an einen Server von
              Google in den USA übertragen und dort gekürzt. In unserem Auftrag wird Google diese
              Informationen benutzen, um Ihre Nutzung unserer Webseite auszuwerten, um Reports über
              die Webseiten-Aktivitäten zusammenzustellen und um weitere mit der Webseiten-Nutzung
              und der Internetnutzung verbundene Dienstleistungen uns gegenüber zu erbringen. Die im
              Rahmen von Google Analytics von Ihrem Browser übermittelte IP-Adresse wird nicht mit
              anderen Daten von Google zusammengeführt. Sie können die Speicherung der Cookies durch
              eine entsprechende Einstellung Ihrer Browser-Software verhindern. Wir weisen Sie
              jedoch darauf hin, dass Sie in diesem Fall gegebenenfalls nicht sämtliche Funktionen
              unserer Webseite vollumfänglich werden nutzen können. Sie können darüber hinaus die
              Erfassung der durch das Cookie erzeugten und auf Ihre Nutzung der Webseite bezogenen
              Daten (inkl. Ihrer IP-Adresse) an Google sowie die Verarbeitung dieser Daten durch
              Google verhindern, indem Sie das unter dem folgenden Link verfügbare Browser-Plugin
              herunterladen und installieren http://tools.google.com/dlpage/gaoptout?hl=de Sie
              können die Erfassung Ihrer Daten durch Google Analytics verhindern, indem Sie auf
              folgenden Link klicken. Es wird ein Opt-Out-Cookie gesetzt, das die Erfassung Ihrer
              Daten bei zukünftigen Besuchen unserer Webseite verhindert:
            </p>

            <p>
              <a onClick={() => {}} href="#!">
                Google Analytics deaktivieren
              </a>
            </p>

            <h2>Rechte des Betroffenen</h2>
            <p>Ihnen stehen folgende Rechte zu:</p>
            <p>
              <strong>Auskunft</strong>
            </p>
            <p>
              Sie haben gemäß Art. 15 DSGVO das Recht, Auskunft über Ihre von uns verarbeiteten
              personenbezogenen Daten zu verlangen. Dieses Auskunftsrecht umfasst dabei
              Informationen über
            </p>
            <ul>
              <li>die Verarbeitungszwecke</li>
              <li>die Kategorien der personenbezogenen Daten</li>
              <li>
                die Empfänger oder Kategorien von Empfängern, gegenüber denen Ihre Daten offengelegt
                wurden oder werden
              </li>
              <li>
                die geplante Speicherdauer oder zumindest die Kriterien für die Festlegung der
                Speicherdauer
              </li>
              <li>
                das Bestehen eines Rechts auf Berichtigung, Löschung, Einschränkung der Verarbeitung
                oder Widerspruch
              </li>
              <li>das Bestehen eines Beschwerderechts bei einer Aufsichtsbehörde</li>
              <li>
                die Herkunft Ihrer personenbezogenen Daten, sofern diese nicht bei uns erhoben
                wurden
              </li>
              <li>
                das Bestehen einer automatisierten Entscheidungsfindung einschließlich Profiling und
                ggf. aussagekräftigen Informationen zu deren Einzelheiten
              </li>
            </ul>

            <p>
              <strong>Berichtigung</strong>
            </p>

            <p>
              Ihnen steht nach Art. 16 DSGVO ein Recht auf unverzügliche Berichtigung unrichtiger
              oder unvollständiger gespeicherter personenbezogener Daten bei uns zu.
            </p>

            <p>
              <strong>Löschung</strong>
            </p>

            <p>
              Sie haben nach Art. 17 DSGVO das Recht, die unverzügliche Löschung Ihrer
              personenbezogenen Daten bei uns zu verlangen, soweit die weitere Verarbeitung nicht
              aus einem der nachfolgenden Gründe erforderlich ist:
            </p>

            <ul>
              <li>zur Ausübung des Rechts auf freie Meinungsäußerung und Information</li>
              <li>
                zur Erfüllung einer rechtlichen Verpflichtung, die die Verarbeitung nach dem Recht
                der Europäischen Union oder der Mitgliedstaaten, dem der Verantwortliche unterliegt,
                erfordert, oder zur Wahrnehmung einer Aufgabe, die im öffentlichen Interesse liegt
                oder in Ausübung öffentlicher Gewalt erfolgt, die dem Verantwortlichen übertragen
                wurde
              </li>
              <li>
                aus Gründen des öffentlichen Interesses im Bereich der öffentlichen Gesundheit gemäß
                Art. 9 Abs. 2 lit. h und i sowie Art. 9 Abs. 3 DSGVO
              </li>
              <li>
                für im öffentlichen Interesse liegende Archivzwecke, wissenschaftliche oder
                historische Forschungszwecke oder für statistische Zwecke gem. Art. 89 Abs. 1 DSGVO,
                soweit das unter Abschnitt a) genannte Recht voraussichtlich die Verwirklichung der
                Ziele dieser Verarbeitung unmöglich macht oder ernsthaft beeinträchtigt
              </li>
              <li>zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen</li>
              <li>Einschränkung der Verarbeitung</li>
              <li>
                Sie können gemäß Art. 18 DSGVO die Einschränkung der Verarbeitung Ihrer
                personenbezogenen Daten aus einem der nachfolgenden Gründe verlangen:
              </li>
              <li>Sie bestreiten die Richtigkeit Ihrer personenbezogenen Daten.</li>
              <li>
                Die Verarbeitung ist unrechtmäßig und Sie lehnen die Löschung der personenbezogenen
                Daten ab.
              </li>
              <li>
                Wir benötigen die personenbezogenen Daten für die Zwecke der Verarbeitung nicht
                länger, Sie benötigen diese jedoch zur Geltendmachung, Ausübung oder Verteidigung
                von Rechtsansprüchen.
              </li>
              <li>Sie legen Widerspruch gegen die Verarbeitung gemäß Art. 21 Abs. 1 DSGVO ein.</li>
            </ul>

            <p>
              <strong>Unterrichtung</strong>
            </p>

            <p>
              Wenn Sie die Berichtigung oder Löschung Ihrer personenbezogenen Daten oder eine
              Einschränkung der Verarbeitung nach Art. 16, Art. 17 Abs. 1 und Art. 18 DSGVO verlangt
              haben, teilen wir dies allen Empfängern, denen Ihre personenbezogenen Daten
              offengelegt wurden, mit, es sei denn, dies erweist sich als unmöglich oder ist mit
              einem unverhältnismäßigen Aufwand verbunden. Sie können von uns verlangen, dass wir
              Ihnen diese Empfänger mitteilen.
            </p>

            <p>
              <strong>Übermittlung</strong>
            </p>
            <p>
              Sie haben das Recht, Ihre personenbezogenen Daten, die Sie uns bereitgestellt haben,
              in einem strukturierten, gängigen und maschinenlesbaren Format zu erhalten. Sie haben
              ebenfalls das Recht, die Übermittlung dieser Daten an einen Dritten zu verlangen,
              sofern die Verarbeitung mithilfe automatisierter Verfahren erfolgte und auf einer
              Einwilligung gemäß Art. 6 Abs. 1 S. 1 lit. a oder Art. 9 Abs. 2 lit. a oder auf einem
              Vertrag gemäß Art. 6 Abs. 1 S. 1 lit. b DSGVO beruht.
            </p>

            <p>
              <strong>Widerruf</strong>
            </p>
            <p>
              Sie haben gemäß Art. 7 Abs. 3 DSGVO das Recht, ihre erteilte Einwilligung jederzeit
              uns gegenüber zu widerrufen. Durch den Widerruf der Einwilligung wird die
              Rechtmäßigkeit der aufgrund der Einwilligung bis zum Widerruf erfolgten Verarbeitung
              nicht berührt. Zukünftig dürfen wir die Datenverarbeitung, die auf Ihrer widerrufenen
              Einwilligung beruhte nicht mehr fortführen.
            </p>

            <p>
              <strong>Beschwerde</strong>
            </p>
            <p>
              Sie haben gemäß Art. 77 DSGVO das Recht, sich bei einer Aufsichtsbehörde zu
              beschweren, wenn Sie der Ansicht sind, dass die Verarbeitung Ihrer personenbezogenen
              Daten gegen die DSGVO verstößt.
            </p>

            <p>
              <strong>Widerspruch</strong>
            </p>
            <p>
              Sofern Ihre personenbezogenen Daten auf Grundlage von berechtigten Interessen gemäß
              Art. 6 Abs. 1 S. 1 lit. f DSGVO verarbeitet werden, haben Sie das Recht, gemäß Art. 21
              DSGVO Widerspruch gegen die Verarbeitung Ihrer personenbezogenen Daten einzulegen,
              soweit dafür Gründe vorliegen, die sich aus Ihrer besonderen Situation ergeben oder
              sich der Widerspruch gegen Direktwerbung richtet. Im letzteren Fall haben Sie ein
              generelles Widerspruchsrecht, das ohne Angabe der besonderen Situation von uns
              umgesetzt wird. Möchten Sie von Ihrem Widerrufs- oder Widerspruchsrecht Gebrauch
              machen, genügt eine E-Mail an silke.blume@incrowdassets.com
            </p>

            <p>
              <strong>Automatisierte Entscheidung im Einzelfall einschließlich Profiling</strong>
            </p>
            <p>
              Sie haben das Recht, nicht einer ausschließlich auf einer automatisierten Verarbeitung
              – einschließlich Profiling – beruhenden Entscheidung unterworfen zu werden, die Ihnen
              gegenüber rechtliche Wirkung entfaltet oder Sie in ähnlicher Weise erheblich
              beeinträchtigt. Dies gilt nicht, wenn die Entscheidung
            </p>

            <ul>
              <li>
                für den Abschluss oder die Erfüllung eines Vertrags zwischen Ihnen und uns
                erforderlich ist
              </li>
              <li>
                aufgrund von Rechtsvorschriften der Europäischen Union oder der Mitgliedstaaten,
                denen wir unterliegen, zulässig ist und diese Rechtsvorschriften angemessene
                Maßnahmen zur Wahrung Ihrer Rechte und Freiheiten sowie Ihrer berechtigten
                Interessen enthalten
              </li>
              <li>mit Ihrer ausdrücklichen Einwilligung erfolgt</li>
            </ul>

            <p>
              Allerdings dürfen diese Entscheidungen nicht auf besonderen Kategorien
              personenbezogener Daten nach Art. 9 Abs. 1 DSGVO beruhen, sofern nicht Art. 9 Abs. 2
              lit. a oder g DSGVO gilt und angemessene Maßnahmen zum Schutz der Rechte und
              Freiheiten sowie Ihrer berechtigten Interessen getroffen wurden. Hinsichtlich der in
              i) und iii) genannten Fälle treffen wir angemessene Maßnahmen, um die Rechte und
              Freiheiten sowie Ihre berechtigten Interessen zu wahren, wozu mindestens das Recht auf
              Erwirkung des Eingreifens einer Person von unserer Seite, auf Darlegung des eigenen
              Standpunkts und auf Anfechtung der Entscheidung gehört.
            </p>

            <h2>Änderung der Datenschutzerklärung</h2>

            <p>
              Sollten wir die Datenschutzerklärung ändern, dann wird dies auf der Webseite kenntlich
              gemacht. Stand 27.06.2018
            </p>
          </div>
        </div>
      </div>
    </section>
  </Layout>
);

export default Datenschutz;
